<template>
  <v-dialog v-model="showDialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        Select Lat/Long on Map
      </v-btn>
    </template>
    <v-card v-if="isLoadingGeolocation">
      <v-card-title>Getting Current Location</v-card-title>
      <v-card-text>
        <v-progress-linear indeterminate />
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>Select Latitude and Longitude</v-card-title>
      <v-card-subtitle>
        Move the marker on the map to update the latitude and longitude values.
      </v-card-subtitle>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-text-field
              v-if="!isUpdatingLatLng"
              v-model="latNum"
              label="Latitude"
              v-mask="latitudeMask"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-if="!isUpdatingLatLng"
              v-model="longNum"
              label="Longitude"
              v-mask="longitudeMask"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <GmapMap
              :center="mapPosition"
              :zoom="9"
              map-type-id="terrain"
              style="height: 300px"
            >
              <GmapMarker
                :draggable="true"
                :position="markerPosition"
                @dragend="markerMoved($event)"
              />
            </GmapMap>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="showDialog = false">Cancel</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="select">Select</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import rules from "@/utils/rules";
import { gmapApi } from "vue2-google-maps";
import { mapState } from "vuex";

const latitudeMask = createNumberMask({
  prefix: "",
  suffix: "",
  allowDecimal: true,
  allowNegative: true,
  includeThousandsSeparator: false,
  integerLimit: 2,
  decimalLimit: 8
});
const longitudeMask = createNumberMask({
  prefix: "",
  suffix: "",
  allowDecimal: true,
  allowNegative: true,
  includeThousandsSeparator: false,
  integerLimit: 3,
  decimalLimit: 8
});

export default {
  name: "LatLongDialog",
  data: () => ({
    isLoadingGeolocation: false,
    isUpdatingLatLng: false,
    mapPosition: null,
    showDialog: false,
    lat: null,
    lng: null,
    latitudeMask,
    longitudeMask,
    rules: {
      required: [rules.required]
    }
  }),
  watch: {
    showDialog(shown) {
      if (!shown) return;
      if (
        this.detailItem &&
        this.detailItem.latitude &&
        this.detailItem.longitude
      ) {
        this.lat = this.detailItem.latitude;
        this.lng = this.detailItem.longitude;
      } else if (navigator.geolocation) {
        this.isLoadingGeolocation = true;
        navigator.geolocation.getCurrentPosition(
          position => {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
            this.mapPosition = Object.assign({}, this.markerPosition);
            this.isLoadingGeolocation = false;
          },
          () => {
            console.error("The Geolocation service failed.");
            this.isLoadingGeolocation = false;
          }
        );
      }
      this.mapPosition = Object.assign({}, this.markerPosition);
    }
  },
  computed: {
    ...mapState("sources", ["detailItem"]),
    google: gmapApi,
    latNum: {
      get: function() {
        if (this.lat !== null) {
          return Number(this.lat);
        } else {
          return this.lat;
        }
      },
      set: function(val) {
        this.lat = val;
      }
    },
    longNum: {
      get: function() {
        if (this.lng !== null) {
          return Number(this.lng);
        } else {
          return this.lng;
        }
      },
      set: function(val) {
        this.lng = val;
      }
    },
    markerPosition() {
      return { lat: this.latNum || 35.137879, lng: this.longNum || -82.836914 };
    }
  },
  methods: {
    markerMoved(evt) {
      this.isUpdatingLatLng = true;
      this.$nextTick(() => {
        this.lat = evt.latLng.lat().toFixed(6);
        this.lng = evt.latLng.lng().toFixed(6);
      });
      this.$nextTick(() => {
        this.isUpdatingLatLng = false;
      });
    },
    select() {
      this.$emit("latLng", this.lat, this.lng);
      this.showDialog = false;
    }
  }
};
</script>

<style scoped></style>
